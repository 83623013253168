import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DropdownItem } from '@proget-shared/form/dropdown';
import { debounceTime, filter, map, Subject, Subscription } from 'rxjs';

import { GridControl } from '../../class/grid-control.class';

@Component({
  selector: 'app-grid-paginator',
  templateUrl: './grid-paginator.component.html',
  styleUrls: ['./grid-paginator.component.scss'],
})
export class GridPaginatorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  public gridControl: GridControl;

  protected readonly limitFormControl = new FormControl();

  @ViewChild('extras', { read: ElementRef })
  protected extrasRef: ElementRef;
  @ViewChild('paginator', { read: ElementRef })
  protected paginatorRef: ElementRef;
  @ViewChild('paginatorContainer', { read: ElementRef })
  protected paginatorContainerRef: ElementRef;

  protected limitsOptions: DropdownItem[] = [];
  protected expanderWidth = 0;
  protected extrasMobile = false;

  private readonly subscription = new Subscription();

  private limits: number[] = [];
  private resizeSubject = new Subject<void>();
  private _itemsCountVisibility = true;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.gridControl) {
      return;
    }

    this.subscription.add(
      this.limitFormControl.valueChanges.subscribe({
        next: (limit) => {
          this.gridControl.setLimit(limit);

          this.updateLimitsOptions();
        },
      })
    );

    this.subscription.add(
      this.gridControl.itemsCount$.subscribe({
        next: () => {
          this.updateExtrasWidth();
        },
      })
    );

    this.subscription.add(
      this.gridControl.params$
        .pipe(map((params) => {
          const defaultLimit = this.limits.length ? this.limits[0] : 10;

          return Number(params.limit) || defaultLimit;
        }))
        .subscribe({
          next: (limit) => {
            this.limitFormControl.setValue(limit, { emitEvent: false });

            this.updateLimitsOptions();
            this.updateExtrasWidth();
          },
        })
    );

    this.subscription.add(
      this.gridControl.limits$
        .pipe(filter((limits) => 0 !== limits.length))
        .subscribe({
          next: (limits) => {
            this.limits = limits.slice();

            this.updateLimitsOptions();
            this.updateExtrasWidth();
          },
        })
    );

    this.subscription.add(
      this.resizeSubject.pipe(debounceTime(100)).subscribe({
        next: () => {
          this.updateExtrasWidth();
        },
      })
    );
  }

  ngAfterViewInit(): void {
    this.updateExtrasWidth(true);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set itemsCountVisibility(visibility: boolean) {
    this._itemsCountVisibility = visibility;
    this.updateExtrasWidth();
  }

  public get itemsCountVisibility(): boolean {
    return this._itemsCountVisibility;
  }

  public get currentPage(): number {
    return this.gridControl ? this.gridControl.getPage() : 1;
  }

  public get maxPage(): number {
    return this.gridControl ? this.gridControl.getMaxPage() : 1;
  }

  public get pagingPagesList(): number[] {
    const range = 2;

    const lowest: number = Math.min(this.maxPage - range * 2, this.currentPage - range);
    const first: number = Math.max(1, lowest);
    const last: number = Math.min(first + range * 2, this.maxPage);

    return Array.apply(null, { length: last - first + 1 }).map(
      Number.call,
      (index: number) => index + first
    );
  }

  public get itemsCount(): number {
    return this.gridControl ? this.gridControl.getItemsCount() : 0;
  }

  protected updateLimitsOptions(): void {
    const selectedLimit = Number(this.limitFormControl.value);
    const isKnownLimit = -1 !== this.limits.indexOf(selectedLimit);
    const values = this.limits.concat(isKnownLimit ? [] : [selectedLimit]);

    this.limitsOptions = values.sort((a, b) => a - b).map((value) => ({
      value,
      label: value.toString(),
    }));
  }

  @HostListener('window:resize')
  protected onResize(): void {
    this.resizeSubject.next();
  }

  protected getItemsCountString(): string {
    return this.itemsCount.toLocaleString();
  }

  protected loadPage(page: number): void {
    if (!this.gridControl) {
      return;
    }

    this.gridControl.setPage(page);
  }

  private updateExtrasWidth(instant = false): void {
    if (!this.extrasRef || !this.paginatorRef || !this.paginatorContainerRef) {
      return;
    }

    if (instant) {
      const containerWidth: number = this.paginatorContainerRef.nativeElement.offsetWidth;
      const paginatorWidth: number = this.paginatorRef.nativeElement.offsetWidth;
      const extrasWidth: number = this.extrasRef.nativeElement.offsetWidth;
      const totalWidth: number = extrasWidth + paginatorWidth;

      this.expanderWidth = Math.min(containerWidth, totalWidth);
      this.extrasMobile = totalWidth > containerWidth;

      return;
    }

    window.requestAnimationFrame(() => {
      this.updateExtrasWidth(true);
    });
  }
}
