import { QueryParams } from '@proget-shared/_common';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  pairwise,
  ReplaySubject,
  shareReplay,
  startWith,
  tap,
} from 'rxjs';

import { ORDER } from '../enum/order.enum';
import { GridControlService } from '../service/grid-control.service';
import { FilterConfig } from '../type/filter-config.type';
import { GridOptions } from '../type/grid-options.type';
import { GroupedQueryParams } from '../type/grouped-query-params.type';
import { ParamsTransformOptions } from '../type/params-transform-options.type';

import { ObjectComparator } from './object-comparator.class';

export class GridControl {
  public readonly params$: Observable<QueryParams>;
  public readonly filters$: Observable<QueryParams>;
  public readonly requestParams$: Observable<QueryParams>;
  public readonly limits$: Observable<number[]>;
  public readonly itemsCount$: Observable<number>;

  private readonly localParamsSubject: BehaviorSubject<QueryParams>;
  private readonly filtersConfigSubject: BehaviorSubject<FilterConfig[]>;
  private readonly usingQueryParamsSubject = new ReplaySubject<boolean>();
  private readonly limitsSubject: BehaviorSubject<number[]>;
  private readonly itemsCountSubject = new BehaviorSubject<number>(0);
  private readonly orderKeyTest = /^order\[(.+)\]$/;
  private readonly defaults: QueryParams;

  private page = 1;
  private limit = 10;
  private itemsCount = 0;
  private itemsCountSet = false;
  private orderField = '';
  private orderDirection: ORDER = ORDER.NONE;
  private filters: QueryParams = {};

  constructor(
    public readonly id: string,
    private readonly gridControlService: GridControlService,
    private _usingQueryParams: boolean,
    options: GridOptions,
    initialParams: QueryParams = null
  ) {
    this.defaults = {
      page: '1',
      limit: this.getDefaultLimit(options.LIMITS).toString(),
    };

    this.localParamsSubject = new BehaviorSubject<QueryParams>(this.defaults);
    this.filtersConfigSubject = new BehaviorSubject<FilterConfig[]>([]);
    this.usingQueryParamsSubject.next(_usingQueryParams);
    this.limitsSubject = new BehaviorSubject(options.LIMITS);

    this.limits$ = this.limitsSubject.asObservable();

    this.itemsCount$ = this.itemsCountSubject.asObservable().pipe(distinctUntilChanged());

    this.params$ = this.getParamsStream(initialParams).pipe(
      distinctUntilChanged(ObjectComparator.compare),
      tap({
        next: (params) => {
          this.page = Number(params.page);
          this.limit = Number(params.limit);
          this.filters = Object.assign({}, this.extractFilters(params));
          this.orderField = '';
          this.orderDirection = ORDER.NONE;

          for (const key in params) {
            if (!params.hasOwnProperty(key)) {
              continue;
            }

            const orderKeyMatch: RegExpMatchArray = key.match(this.orderKeyTest);

            if (orderKeyMatch) {
              this.orderField = orderKeyMatch[1];
              this.orderDirection = params[key] as ORDER;
              break;
            }
          }
        },
      }),
      shareReplay(1)
    );

    this.filters$ = this.params$.pipe(
      map((): QueryParams => this.filters),
      distinctUntilChanged(ObjectComparator.compare)
    );

    this.requestParams$ = this.getRequestParams$();
  }

  public get filtersConfig$(): Observable<FilterConfig[]> {
    return this.filtersConfigSubject as Observable<FilterConfig[]>;
  }

  public get params(): QueryParams {
    const params: QueryParams = Object.assign({}, this.filters, {
      page: this.page.toString(),
      limit: this.limit.toString(),
    });

    if (this.orderField && this.orderDirection) {
      params[`order[${this.orderField}]`] = this.orderDirection;
    }

    return params;
  }

  public get requestParams(): QueryParams {
    return this.paramsToRequestParams(this.params);
  }

  public get urlParams(): QueryParams {
    return this.filterDefaults(this.params);
  }

  public get usingQueryParams(): boolean {
    return this._usingQueryParams;
  }

  public getRequestParams(options: ParamsTransformOptions = {}): QueryParams {
    return this.paramsToRequestParams(this.params, options);
  }

  public getRequestParams$(options: ParamsTransformOptions = {}): Observable<QueryParams> {
    return this.params$.pipe(
      map((params) => this.paramsToRequestParams(params, options)),
      shareReplay(1)
    );
  }

  public useQueryParams(use: boolean): void {
    this._usingQueryParams = use && this.gridControlService.queryParamsAvailable();
    this.gridControlService.readQueryParams();
    this.usingQueryParamsSubject.next(this.usingQueryParams);
  }

  public setLimits(limits: number[]): void {
    this.defaults.limit = this.getDefaultLimit(limits).toString();
    this.limitsSubject.next(limits);
  }

  public setFilters(filters: QueryParams): void {
    this.filters = filters;
    this.page = 1;

    this.emitParams();
  }

  public getFilters(): QueryParams {
    return this.filters;
  }

  public setPage(page: number): void {
    this.page = Math.min(this.getMaxPage(), page);
    this.emitParams();
  }

  public getPage(): number {
    return this.page;
  }

  public getMaxPage(): number {
    return Math.max(1, Math.ceil(this.itemsCount / this.limit));
  }

  public setLimit(limit: number): void {
    this.limit = Math.max(1, limit);
    this.setPage(this.page); // updates page if over items count
    this.emitParams();
  }

  public getLimit(): number {
    return this.limit;
  }

  public setItemsCount(totalItemsCount: number): void {
    this.itemsCount = totalItemsCount;
    this.itemsCountSet = true;

    this.itemsCountSubject.next(totalItemsCount);

    if (this.page * this.limit > totalItemsCount && 1 < this.page) {
      this.setPage(this.getMaxPage());
    }
  }

  public getItemsCount(): number {
    return this.itemsCount;
  }

  public isItemsCountSet(): boolean {
    return this.itemsCountSet;
  }

  public setOrder(field: string, direction: ORDER): void {
    this.orderField = field;
    this.orderDirection = direction;
    this.emitParams();
  }

  public setFiltersConfig(config: FilterConfig[]): void {
    this.filtersConfigSubject.next(config);
  }

  public getOrderField(): string {
    return this.orderField;
  }

  public getOrderDirection(): ORDER {
    return this.orderDirection;
  }

  public sliceCurrentPage(input: any[]): any[] {
    if (!(input instanceof Array)) {
      return [];
    }

    const first: number = (this.page - 1) * this.limit;
    const last: number = first + this.limit;

    return this.sortItems(input.slice()).slice(first, last);
  }

  private sortItems(events: any[]): any[] {
    const eventsCopy: any[] = events.slice();

    if (ORDER.NONE === this.orderDirection || !this.orderField) {
      return eventsCopy;
    }

    return eventsCopy.sort((a: any, b: any): number => {
      const aValue: string = this.findSortValue(a, this.orderField);
      const bValue: string = this.findSortValue(b, this.orderField);

      if (this.isNumber(aValue) && this.isNumber(bValue)) {
        return ORDER.DESC === this.orderDirection ? Number(bValue) - Number(aValue) : Number(aValue) - Number(bValue);
      }

      return ORDER.DESC === this.orderDirection ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
    });
  }

  private isNumber(value: any): boolean {
    return null !== value && isFinite(value);
  }

  private findSortValue(object: any, sortKey: string): string {
    const value: any = sortKey.split('.').reduce((result: any, keyPart: string): any => result?.[keyPart], object);

    if (!value) {
      return '';
    }

    return value.toString();
  }

  private getParamsStream(initialParams: QueryParams): Observable<QueryParams> {
    return combineLatest([
      this.localParamsSubject.asObservable(),
      this.gridControlService.queryParamsAvailable()
        ? this.gridControlService.controlsParams$.pipe(
          delay(0), // wait for /possible/ custom limits from component
          map((controlsParams: GroupedQueryParams) => controlsParams[this.id] || {}),
          map((params: QueryParams) => this.addDefaults(params)),
          startWith(null as QueryParams) // prevent waiting for params if they are not used, ignored in first filter
        )
        : of({}),
      this.usingQueryParamsSubject.pipe(distinctUntilChanged()),
    ]).pipe(
      filter(([localParams, queryParams, useQueryParams]) => {
        const passingQueryParams = useQueryParams && queryParams !== null;
        const passingLocalParams = !useQueryParams && localParams !== null;

        return passingQueryParams || passingLocalParams;
      }),
      startWith([null, null, false]), // start with null to detect first params change
      pairwise(),
      map(
        ([[prevLocalParams], [localParams, queryParams, useQueryParams]]: [
          [QueryParams, QueryParams, boolean],
          [QueryParams, QueryParams, boolean],
        ]) => {
          const currentParams: QueryParams = useQueryParams ? queryParams : localParams;
          const firstParams: boolean = null === prevLocalParams;

          // current params are more important than initial params
          if (firstParams && Object.keys(this.filterDefaults(currentParams)).length === 0) {
            return this.addDefaults(initialParams);
          }

          return currentParams;
        }
      )
    );
  }

  private emitParams(): void {
    if (!this.usingQueryParams) {
      this.localParamsSubject.next(this.params);

      return;
    }

    this.gridControlService.writeQueryParams();
  }

  private filterDefaults(params: QueryParams): QueryParams {
    const clearedParams: QueryParams = {};

    for (const key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }

      if (params[key] !== this.defaults[key]) {
        clearedParams[key] = params[key];
      }
    }

    return clearedParams;
  }

  private addDefaults(params: QueryParams): QueryParams {
    return Object.assign({}, this.defaults, params);
  }

  private paramsToRequestParams(
    params: QueryParams,
    options: { filterKeyPrefix?: string; filterKeySuffix?: string; orderKey?: string } = {}
  ): QueryParams {
    const requestParams: QueryParams = {};
    const mergedOptions: ParamsTransformOptions = Object.assign(
      {
        filterKeyPrefix: '',
        filterKeySuffix: '',
        orderKey: 'order',
        aliases: {},
      },
      options
    );

    for (const key in params) {
      if (!params.hasOwnProperty(key) || key === 'page' || key === 'limit') {
        continue;
      }

      const orderKeyMatch: RegExpMatchArray = key.match(this.orderKeyTest);

      if (orderKeyMatch) {
        requestParams[`${mergedOptions.orderKey}[${orderKeyMatch[1]}]`] = params[key];

        continue;
      }

      const arraySuffix: string = params[key] instanceof Array ? '[]' : '';
      const filterKey = `${mergedOptions.filterKeyPrefix}${key}${mergedOptions.filterKeySuffix}${arraySuffix}`;

      requestParams[filterKey] = params[key];
    }

    const limit: number = this.isNumber(params.limit) ? Number(params.limit) : 10;
    const page: number = this.isNumber(params.page) ? Number(params.page) : 1;
    const offset: number = (page - 1) * limit;

    requestParams.limit = limit.toString();
    requestParams.offset = offset.toString();

    for (const aliasName in mergedOptions.aliases) {
      if (!mergedOptions.aliases.hasOwnProperty(aliasName) || !requestParams.hasOwnProperty(aliasName)) {
        continue;
      }

      for (const valueToReplace in mergedOptions.aliases[aliasName]) {
        if (!mergedOptions.aliases[aliasName].hasOwnProperty(valueToReplace)) {
          continue;
        }

        const isParamValueArray = Array.isArray(requestParams[aliasName]);
        const paramValueArray = (isParamValueArray ? requestParams[aliasName] : [requestParams[aliasName]]) as string[];
        const valueToReplaceIndex = paramValueArray.indexOf(valueToReplace);

        if (valueToReplaceIndex === -1) {
          continue;
        }

        const newValue = mergedOptions.aliases[aliasName][valueToReplace];

        // remove existing value
        if (isParamValueArray) {
          const paramValueCopy = (requestParams[aliasName] as string[]).slice();

          paramValueCopy.splice(valueToReplaceIndex, 1);
          requestParams[aliasName] = paramValueCopy;
        } else {
          delete requestParams[aliasName];
        }

        const newValueGroup = typeof newValue === 'object' && !Array.isArray(newValue) && newValue !== null
          // already an object
          ? newValue
          // force object
          : { [aliasName]: newValue as (string | string[]) };

        for (const newValueName in newValueGroup) {
          if (!newValueGroup.hasOwnProperty(newValueName)) {
            continue;
          }

          if (requestParams.hasOwnProperty(newValueName) && Array.isArray(requestParams[newValueName])) {
            requestParams[newValueName] = requestParams[newValueName].concat(newValueGroup[newValueName]);

            continue;
          }

          requestParams[newValueName] = newValueGroup[newValueName];
        }

        if (Array.isArray(requestParams[aliasName]) && requestParams[aliasName].length === 0) {
          delete requestParams[aliasName];
        }
      }
    }

    return requestParams;
  }

  private extractFilters(params: QueryParams): QueryParams {
    const filters: QueryParams = {};

    for (const key in params) {
      if (params.hasOwnProperty(key) && 'page' !== key && 'limit' !== key && !this.orderKeyTest.test(key)) {
        filters[key] = params[key];
      }
    }

    return filters;
  }

  private getDefaultLimit(limits: number[]): number {
    return Array.isArray(limits) && limits.length ? limits[0] : 10;
  }
}
